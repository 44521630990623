import { useState, useRef, useEffect } from "react";
import { JSEncrypt } from "jsencrypt";

const Last = props => {
    const csessionid = document.getElementById("csessionid").value;
    const queryEndRef = useRef(null);
    const [enteredEmail, setEnteredEmail] = useState('');
    const [emailEmpty, setEmailEmpty] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [emailDisable, setEmailDisable] = useState(false);
    const [enteredFeedback, setEnteredFeedback] = useState('');
    const [feedbackEmpty, setFeedbackEmpty] = useState(false);
    const [feedbackInvalid, setFeedbackInvalid] = useState(false);
    const [enteredOtp, setEnteredOtp] = useState('');
    const [otpEmpty, setOtpEmpty] = useState(false);
    const [otpInvalid, setOtpInvalid] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const [message, setMessage] = useState('');
    const [limit, setLimit] = useState(false);

    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_KEY);
    const encryptedPublicKey = encrypt.encrypt(process.env.REACT_APP_TEXT);

    useEffect(() => {
        if(csessionid !== '') {
            setEnteredEmail(csessionid);
            setEmailDisable(true);
        } else {
            setEmailDisable(false);
        }
    }, []);

    const emailChangeHandler = (event) => {
        setEnteredEmail(event.target.value);
        setEmailEmpty(false);
        setEmailInvalid(false);
    };

    const emailBlurHandler = () => {
        if (enteredEmail.trim() === '') {
            setEmailEmpty(true);
        } else if (!enteredEmail.trim().match('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')) {
            setEmailInvalid(true);
        }
    }
    
    const feedbackChangeHandler = (event) => {
        setEnteredFeedback(event.target.value);
        setFeedbackEmpty(false);
        setFeedbackInvalid(false);
    };

    const feedbackBlurHandler = () => {
        if (enteredFeedback.trim() === '') {
            setFeedbackEmpty(true);
        } else if (enteredFeedback.trim().includes('`') || enteredFeedback.trim().includes('~') || enteredFeedback.trim().includes('^') || enteredFeedback.trim().includes('[') || enteredFeedback.trim().includes(']') || enteredFeedback.trim().includes('{') || enteredFeedback.trim().includes('}') || enteredFeedback.trim().includes('|') || enteredFeedback.trim().includes('\\') || enteredFeedback.trim().includes('/') || enteredFeedback.trim().includes('&') || enteredFeedback.trim().includes('=')) {
            setFeedbackInvalid(true);
        }
    }

    const otpChangeHandler = (event) => {
        setEnteredOtp(event.target.value);
        setOtpEmpty(false);
        setOtpInvalid(false);
    };

    const otpBlurHandler = () => {
        if (enteredOtp.trim() === '') {
            setOtpEmpty(true);
        } else if (!enteredOtp.trim().match('^([1-9][0-9]*)$')) {
            setOtpInvalid(true);
        }
    }

    const scrollToBottom = () => queryEndRef.current.scrollIntoView();

    useEffect(scrollToBottom, [trigger]);
    
    const submitDataHandler = async () => {
        let invalidEmail, invalidFeedback, emptyEmail, emptyFeedback, invalidOtp, emptyOtp = false;

        if (enteredEmail.trim() === '') {
          setEmailEmpty(true);
          emptyEmail = true;
        } else if (!enteredEmail.trim().match('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')) {
          setEmailInvalid(true);
          invalidEmail = true;
        }

        if (enteredFeedback.trim() === '') {
          setFeedbackEmpty(true);
          emptyFeedback = true;
        } else if (enteredFeedback.trim().includes('`') || enteredFeedback.trim().includes('~') || enteredFeedback.trim().includes('^') || enteredFeedback.trim().includes('[') || enteredFeedback.trim().includes(']') || enteredFeedback.trim().includes('{') || enteredFeedback.trim().includes('}') || enteredFeedback.trim().includes('|') || enteredFeedback.trim().includes('\\') || enteredFeedback.trim().includes('/') || enteredFeedback.trim().includes('&') || enteredFeedback.trim().includes('=')) {
          setFeedbackInvalid(true);
          invalidFeedback = true;
        }

        if (enteredOtp.trim() === '') {
            setOtpEmpty(true);
            emptyOtp = true;
        } else if (!enteredOtp.trim().match('^([1-9][0-9]*)$')) {
            setOtpInvalid(true);
            invalidOtp = true;
        }

        if (!emptyEmail && !emptyFeedback && !invalidEmail && !invalidFeedback) {
            if (!trigger) {setOtpEmpty(false); setOtpInvalid(false);}
            let otpValue;
            if (!trigger && emptyOtp) {
                otpValue = 'OT';
            } else {
                otpValue = enteredOtp;
            }

            if (trigger && (emptyOtp || invalidOtp)) {
                return;
            }

            const response = await fetch(`https://staging.usgovcert.net/chatbotfb?EI=${enteredEmail}&FB=${enteredFeedback}&FT=${props.type}&TP=${otpValue}&RTP=NOTP&U=${encryptedPublicKey}`, {method: 'POST', mode: 'cors'});

            const data = await response.json();
            if(data.rettype === 'OTP') {
                setTrigger(true);
                setOtpEmpty(false);
                setOtpInvalid(false);
                setMessage(data.data);
            }

            if(data.rettype === 'FIVE') {
                setOtpEmpty(false);
                setOtpInvalid(false);
                setMessage(data.data);
                setLimit(true);
            }

            if(data.rettype === 'LIMIT') {
                setOtpEmpty(false);
                setOtpInvalid(false);
                setMessage(data.data);
                setLimit(true);
            }

            if(data.rettype === 'SUBMIT') {
                props.showHide();
            }

            if(data.rettype === 'INVALID') {
                setOtpInvalid(true);
            }
        }
    };

    const regenerateOtp = async () => {
        setOtpEmpty(false);
        setOtpInvalid(false);
        const response = await fetch(`https://staging.usgovcert.net/chatbotfb?EI=${enteredEmail}&FB=${enteredFeedback}&FT=${props.type}&TP=OT&RTP=ROTP&U=${encryptedPublicKey}`, {method: 'POST', mode: 'cors'});
        const data = await response.json();

        if(data.rettype === 'OTP') {
            setTrigger(true);
            setOtpEmpty(false);
            setOtpInvalid(false);
            setMessage(data.data);
        }

        if(data.rettype === 'LIMIT') {
            setTrigger(true);
            setOtpEmpty(false);
            setOtpInvalid(false);
            setMessage(data.data);
        }
    };

    return (
        <div>
            <div className='cardfeedback'>
                <input type='text' className='email-field' placeholder="Enter your email" onChange={emailChangeHandler} onBlur={emailBlurHandler} disabled={emailDisable} value={enteredEmail}/>
                {emailEmpty && <p className='fields-alert'>'Email Address' is required</p>}
                {emailInvalid && <p className='fields-alert'>Invalid Email Address</p>}
                <textarea className='feedback-field' placeholder="Type your feedback/query (max 200 characters)" onChange={feedbackChangeHandler} onBlur={feedbackBlurHandler} value={enteredFeedback} maxLength={200}/>
                {feedbackEmpty && <p className='fields-alert'>'Feedback/Query' is required</p>}
                {feedbackInvalid && <p className='fields-alert'>Invalid Feedback/Query. Following special characters are not allowed ` ~ ^ & = &#123; &#125; [ ] \ | /</p>}
                {trigger && <div><div style={{display: 'flex'}}><div><input maxLength={6} type='text' className='otp-field' placeholder="Enter OTP" onChange={otpChangeHandler} onBlur={otpBlurHandler} value={enteredOtp}/></div>
                <div style={{marginBottom:'20px'}}><a className='regenerate' onClick={regenerateOtp}>Regenerate OTP</a></div></div>
                {otpEmpty && <div style={{width: '65%'}}><p className='fields-alert'>'OTP' is required</p></div>}
                {otpInvalid && <div style={{width: '65%'}}><p className='fields-alert'>Invalid OTP</p></div>}
                <div><p style={{fontWeight: '550', textAlign:'center', marginBottom:'0px', fontFamily: 'Lato', color:'black'}}>{message}</p></div></div>}
                {!trigger && limit && <div><p style={{fontWeight: '550', textAlign:'center', marginBottom:'0px', fontFamily: 'Lato', color:'black'}}>{message}</p></div>}
                <button className='feedback-submit' onClick={submitDataHandler}>Submit</button>
            </div>
            <div style={{paddingTop: '30px'}} ref={queryEndRef}/>
        </div>
    );
};

export default Last;