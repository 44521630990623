import { useState, useEffect } from 'react';
import { JSEncrypt } from "jsencrypt";
import './App.css';
import Last from './Last';
import { FiRefreshCcw } from "react-icons/fi";
import { FaRegThumbsUp, FaRegThumbsDown, FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { MdCircle } from "react-icons/md";
import { IoMdChatboxes, IoMdClose } from "react-icons/io";
import Queries from './Queries';
let tempPrevSelectedState = {};

function App() {
  const [hide, setHide] = useState(false);
  const [show, chatShow] = useState(false);
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  const [mainList, setMainList] = useState([]);
  const [feedback, setFeedback] = useState(false);
  const [selectedStates, setSelectedStates] = useState({level1: 0, level2: 0, Level3: 0, ANS: 0});

  const apiCall = async (level, id, parentLevel) => {
    setLike(false);
    setDislike(false);
    setFeedback(false);
    setHide(false);
    const fetchedQuery1 = [];
    const fetchedQuery2 = [];
    let temp, tempSelectedState;

    setSelectedStates({...selectedStates, [parentLevel]:id});
    tempSelectedState = {Level: level, ID: id};

    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_KEY);
    const encryptedPublicKey = encrypt.encrypt(process.env.REACT_APP_TEXT);
    
    const response1 = await fetch(`https://staging.usgovcert.net/chatbot?L=${level}&T=T&I=${id}&U=${encryptedPublicKey}`, {method: 'POST', mode: 'cors'});
    const data1 = await response1.json();
    for (const key in data1) {
      fetchedQuery1.push({
        text: data1[key].text,
      });
    }
    temp = {text: fetchedQuery1};
    
    const response2 = await fetch(`https://staging.usgovcert.net/chatbot?L=${level}&T=L&I=${id}&U=${encryptedPublicKey}`, {method: 'POST', mode: 'cors'});
    const data2 = await response2.json();
    for (const key in data2) {
      fetchedQuery2.push({
        id: data2[key].id,
        query: data2[key].lnktext,
        level: data2[key].lookup,
      });
    }
    temp = {...temp, btnArray: fetchedQuery2, level: level};

    if (tempSelectedState.Level === tempPrevSelectedState.Level) {
      if (tempSelectedState.Level === 'level2') {
        mainList.pop();
        setSelectedStates({...selectedStates, [parentLevel]:id, level2: 0, Level3: 0});
        setMainList([...mainList, temp]);
      } else if (tempSelectedState.Level === 'Level3') {
        mainList.pop();
        setSelectedStates({...selectedStates, [parentLevel]:id, Level3: 0});
        setMainList([...mainList, temp]);
      } else if (tempSelectedState.Level === 'ANS') {
        mainList.pop();
        setMainList([...mainList, temp]);
        setFeedback(true);
      }
    } else {
      if (tempSelectedState.Level === "level1") {
        setMainList([temp]);
        setSelectedStates({level1: 0, level2: 0, Level3: 0, ANS: 0});
      }
      if (tempSelectedState.Level === "level2") {
        if (mainList.length < 2) {
          setMainList([...mainList, temp]);
        } else {
          let tempList = mainList[0];
          tempList = [tempList, temp];
          setMainList(tempList);
        }
      }
      if (tempSelectedState.Level === "Level3") {
        if (mainList.length < 3) {
          setMainList([...mainList, temp]);
        } else {
          let tempList = mainList[0];
          tempList = [tempList, mainList[1]];
          tempList = [...tempList, temp];
          setMainList(tempList);
        }
      }
      if (tempSelectedState.Level === "ANS") {
        setMainList([...mainList, temp]);
        setFeedback(true);
      }
    }

    tempPrevSelectedState = tempSelectedState;
  };

  useEffect(() => {
    apiCall('level1', 0, 'level1');
  }, []);

  const refreshHandler = () => {
    apiCall('level1', 0, 'level1');
  };

  const showHide = () => {
    setHide(true);
  };
  
  return (
    <div>
      <header>
      {!show && <div><MdCircle className='App-logo' onClick={() => chatShow(true)}/><IoMdChatboxes className='App-log' onClick={() => chatShow(true)}/></div>}
        {show && <div className='App-chat'>
            <div className='App-topbar'>
              <img src='img/USGOVCERTCHAT.png' className='App-usgovcert' alt='USGOVCERTCHAT'/>
              <FiRefreshCcw className='App-reboot' title='Refresh' onClick={refreshHandler}/>
              <IoMdClose className='App-minimize' title='Close' onClick={()=> chatShow(false)}/>
            </div>
            <div className='App-chat-area'>
              {mainList.map((x) => <Queries key={Math.random().toString()} data={selectedStates} currentData={x} onClick={(obj, parentLevel) => apiCall(obj.level, obj.id, parentLevel)}/>)}
              {feedback && <div>
                <div className='App-last-section'/>
                {!hide && <><div className='App-thumbs'>
                  {!like && <FaRegThumbsUp className='App-like-dislike' title='Like' onClick={() => {setLike(true); setDislike(false);}}/>}
                  {like && <FaThumbsUp className='App-like-dislike' title='Like'/>}
                  {!dislike && <FaRegThumbsDown className='App-like-dislike' title='Dislike' onClick={() => {setDislike(true); setLike(false);}}/>}
                  {dislike && <FaThumbsDown className='App-like-dislike' title='Dislike'/>}
                </div>
                {like && <Last showHide={showHide} type='P'/>}{dislike && <Last key={Math.random().toString()} showHide={showHide} type='N'/>}</>}
                {hide && <div><p style={{textAlign:'center', color:'green', fontFamily: 'Lato'}}><b>Feedback/Query submitted</b></p></div>}
              </div>}
            </div>
        </div>}
      </header>
    </div>
  );
};

export default App;